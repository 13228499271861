import React from 'react';

function LegacyShopWrapper({ children }) {
    return (
        <>
            <div className="shop_wrapper">
                <div className="App">{children}</div>
            </div>
            {/* Failsafe */}
            <div style={{ clear: 'both' }} />
        </>
    );
}

export default LegacyShopWrapper;
