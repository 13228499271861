import React from 'react';
import { useSelector } from 'react-redux';
import { categoriesResponseSelector, presentationSelector } from '../../../modules/selectors/standort/categories';
import loadable from '@loadable/component';
import ReduxSuspense from '../../components/ReduxSuspense/ReduxSuspense';
import LegacyShopWrapper from '../../LegacyShopWrapper';
import BreadCrumbSelector from '../../components/BreadCrumb/BreadCrumbSelector';

const AreaSubLocation = loadable(() => import('../AreaSubLocation/AreaSubLocation'));
const CategoryLandingPage = loadable(() => import('./CategoryLandingPage'));
const CategoryOverview = loadable(() => import('./CategoryOverview'));
const ProductList = loadable(() => import('../products'));
const ServiceCategoryList = loadable(() => import('./ServiceCategoryList'));

// TODO: move BreadCrumbs into child components

function CategorySwitch() {
    const presentation = useSelector(presentationSelector);
    const categoriesResponse = useSelector(categoriesResponseSelector);

    let content;

    switch (presentation) {
        case 'subLocation':
            content = <AreaSubLocation />;
            break;
        case 'productListWithoutPictures':
        case 'productListe':
            content = <ProductList />;
            break;
        case 'categoryLandingPage':
            content = (
                <>
                    <BreadCrumbSelector />
                    <LegacyShopWrapper>
                        <CategoryLandingPage />
                    </LegacyShopWrapper>
                </>
            );
            break;
        case 'categoryList':
            content = <CategoryOverview />;
            break;
        case 'serviceList':
            content = (
                <>
                    <BreadCrumbSelector />
                    <ServiceCategoryList />
                </>
            );
            break;
        default:
            content = <AreaSubLocation />;
    }

    return <ReduxSuspense response={categoriesResponse}>{content}</ReduxSuspense>;
}

export default CategorySwitch;
